import { fadeOut } from "./helpers";

document.addEventListener('turbo:load', () => {

  if (document.querySelector('.wahltool')) {
    // ------- TOP SCROLLER
    const scrollTarget = document.getElementById("candidates");
    const candidatesAnchor = document.getElementById("candidates-anchor");
    const topScroller = document.querySelector('#scroll-to-candidates-button');

    // Create an IntersectionObserver that will be notified when the anchor element enters or leaves the viewport
    var observer = new IntersectionObserver(function (entries) {
      // If the anchor element intersects with the viewport
      if (entries[0].isIntersecting) {
        // Show the scroll indicator element
        topScroller.classList.remove('d-none');
      } else {
        // Hide the scroll indicator element
        topScroller.classList.add('d-none');
      }
    });

    // Start observing the anchor element
    observer.observe(scrollTarget);

    // Top scroller click
    document.querySelector('#scroll-to-candidates-button').addEventListener('click', () => {
      candidatesAnchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    })


    // ------- FILTER STUFF
    const singleFilterControls = document.querySelectorAll('.singleselect-filter');
    const singleFilterStates = { agh: '' }
    const multipleFilterStates = { partei: [], enteignung: [] }
    const multiselectFilterControls = document.querySelectorAll('.multiselect-filter');
    const candidates = document.querySelectorAll('.candidate');
    const resetFiltersBtn = document.querySelector('#reset-filters');
    const showFiltersBtn = document.querySelector('#show-filters');
    const wahlkreisDisplay = document.querySelector('#wahlkreis-display')
    const wahlkreisDisplayValue = document.querySelector('#wahlkreis-display-value');
    const alertMsg = document.querySelector('#no-wahlkreis-found');

    // ----- BEGIN Try hiding filter display after some scrolling
    const filterObserverAnchor = document.querySelector('#filter-observer');
    let filterToggleState = false;

    showFiltersBtn.addEventListener('click', () => {
      filterToggleState = !filterToggleState;
    });

    var filterObserver = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting) {
        if (filterToggleState) {
          showFiltersBtn.click();
          filterToggleState = false;

        }
      }
    });

    filterObserver.observe(filterObserverAnchor);
    // ----- END Try hiding filter display after some scrolling

    const resetFilters = (e) => {
      e.preventDefault();

      singleFilterControls.forEach((filterControl) => {
        filterControl.value = '';
        filterControl.dispatchEvent(new Event('keyup'));
      });

      addressField.value = '';
      fadeOut(wahlkreisDisplay);
      fadeOut(alertMsg);

      document.querySelectorAll('.multiselect-filter').forEach((filterField) => {
        filterField.checked = false;
        filterField.dispatchEvent(new Event('change'));
      })

      // Reset AGH state
      const notAGHField = document.querySelector('#agh-filter').querySelectorAll('.form-check-input')[1]
      notAGHField.checked = true;
      notAGHField.dispatchEvent(new Event('change'));
    }

    resetFiltersBtn.addEventListener('click', (e) => resetFilters(e))


    const updateSingleFilterStates = () => {
      singleFilterControls.forEach((filterControl) => {
        const filterName = filterControl.dataset.filter;

        singleFilterStates[filterName] = filterControl.value;
      })
    }
    const updateMultipleFilterStates = (filterControl) => {
      const checked = filterControl.checked;
      const filter = filterControl.dataset.filter;
      const value = filterControl.value;

      // Add item if checked to filterStates, remove otherwise
      if (checked) {
        multipleFilterStates[filter].push(value);
      } else {
        const index = multipleFilterStates[filter].indexOf(value);
        multipleFilterStates[filter].splice(index, 1);
      }
    }

    const filterMatch = (candidate) => {
      let match = true;

      // Traverse and compare all singleFilterStates and compare 'candidate'
      Object.keys(singleFilterStates).forEach((filter) => {
        const candidateValue = candidate.dataset[filter].toUpperCase();
        const filterValue = singleFilterStates[filter].toUpperCase();
        match = match && candidateValue.includes(filterValue);
      })

      // Traverse and compare all multipleFilterStates and check if 'candidate'
      // contains at least 1 filterItem per item if anyth ing is set
      // If not set success false leading to non-match
      Object.keys(multipleFilterStates).forEach((filter) => {
        let success = true;
        const filterProperty = multipleFilterStates[filter];

        if (filterProperty.length === 0) return;
        if (!filterProperty.includes(candidate.dataset[filter])) success = false;

        match = match && success;
      });

      return match;
    }

    const filterCandidates = () => {
      const noResultMessage = document.querySelector('#no-result-message');
      let count = 0;

      candidates.forEach((candidate) => {
        if (filterMatch(candidate)) {
          candidate.classList.remove('d-none');
          count = count + 1;
          noResultMessage.classList.add('d-none')
        } else {
          candidate.classList.add('d-none');
        }

        // Show no results message if no match
        if (count === 0) noResultMessage.classList.remove('d-none')
      });
    }

    singleFilterControls.forEach((filterControl) => {
      filterControl.addEventListener('keyup', (event) => {
        // Except return key
        const key = event.keyCode || event.charCode;
        if (key === 13) return;

        // Trigger filtering
        updateSingleFilterStates();
        filterCandidates();
      })
    })

    multiselectFilterControls.forEach((filterControl) => {
      filterControl.addEventListener('change', () => {
        updateMultipleFilterStates(filterControl);
        filterCandidates();
      })
    })

    // AGH radio Btns
    document.querySelector('#agh-filter').querySelectorAll('.form-check-input').forEach((radio) => {
      radio.addEventListener('change', () => {
        singleFilterStates['agh'] = radio.value;
        filterCandidates();
      })
    });

    // Rotate filterDisplayBtn caret
    showFiltersBtn.addEventListener('click', () => {
      document.querySelectorAll('.caret').forEach((caret) => caret.classList.toggle('d-none'))
    })


    // ------- GEOCODER STUFF
    const submitAddressBtn = document.querySelector('#submit-address');
    const filterWahlkreisField = document.querySelector('#filter-wahlkreis');
    const addressForm = document.querySelector('#address-form');
    const addressField = document.querySelector('#address');
    const submitText = document.querySelector('#submit-address > #submit-text');
    const submitSpinner = document.querySelector('#submit-address > #submit-spinner');

    const changefilterWahlkreis = (value) => {
      filterWahlkreisField.value = value;
      filterWahlkreisField.dispatchEvent(new Event('keyup'));
    }

    const setSubmitBtnState = ({ active }) => {
      if (active) {
        submitAddressBtn.disabled = false;
        submitSpinner.classList.add('d-none')
        submitText.classList.remove('d-none')
      } else {
        submitAddressBtn.disabled = true;
        submitSpinner.classList.remove('d-none')
        submitText.classList.add('d-none')
      }
    }

    const displayWahlkreis = (wahlkreis) => {
      wahlkreisDisplayValue.innerHTML = wahlkreis;
      wahlkreisDisplay.classList.remove('d-none');
    }


    const showError = () => {
      alertMsg.classList.remove('d-none');
      setTimeout(() => fadeOut(alertMsg), 5000);
      changefilterWahlkreis('');
      setSubmitBtnState({ active: true })
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }

    const showWahlkreis = (data) => {
      changefilterWahlkreis(data.name);
      displayWahlkreis(data.name);
      setSubmitBtnState({ active: true });
      scrollTarget.scrollIntoView({
        top: '50',
        behavior: 'smooth',
        block: 'start'
      })

    }

    // Query Wahlkreis
    // If found Wahlkreis set Wahlkreis input field with result and trigger change ('keyup')
    const queryWahlkreis = () => {
      const address = addressField.value;
      const queryAddress = `https://dwe-deputees-check.fly.dev/?address=${address}`

      // Disable button as long as async request ongoing
      setSubmitBtnState({ active: false })
      wahlkreisDisplay.classList.add('d-none');

      fetch(queryAddress, {
        method: 'GET'
      })
        .then(response => response.json())
        .then(data => {
          if (data.data.name) {
            showWahlkreis(data.data);
          } else {
            showError();
          }
        })
        .catch(() => {
          showError();
        });

    }

    // Trigger search on submit
    addressForm.addEventListener('submit', (event) => {
      event.preventDefault();
      queryWahlkreis()
    })
  }
});