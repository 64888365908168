function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
  }
}

function shuffleList(ul) {
  // Filter out the "SUPPORTED BY" `<li />`.
  const children = Array.from(ul.children).filter(child => !Array.from(child.classList).includes('supported-text'));

  shuffleArray(children);

  children.forEach(child => ul.appendChild(child));
}

function shuffleSupportersList() {
  const ul = document.getElementById('supporters-list');

  if (ul === null) {
    return;
  }

  shuffleList(ul);
}

/*
  This fills out the list with clones of the <li> items so it looks like it's scrolling infinitely,
  even if we don't have enough supports to fill the whole width of the container.
*/
function prepareSupportsList() {
  /* Fill out the list with clones of the <li> items */

  const ul = document.getElementById('supporters-list');

  if (ul === null) {
    return;
  }

  const ulWidth = ul.offsetWidth;

  // Get the <li> items to clone
  const listItems = ul.querySelectorAll('.supporters-list-item');

  // Calculate the total width of all <li> items
  const listItemWidth = Array.from(listItems).reduce((total, item) => total + item.offsetWidth, 0);

  // Calculate how many times all the <li> items can fit inside the <ul> horizontally
  const numChunks = Math.floor(ulWidth / listItemWidth);

  // Clone all the <li> items and add them to the <ul> the calculated number of times
  for (let i = 0; i < numChunks + 1; i++) {
    listItems.forEach(item => {
      const clone = item.cloneNode(true);
      ul.appendChild(clone);
    });
  }
}

function createSupportedByLi() {
  const li = document.createElement('li');
  li.className = 'd-flex supporters-list-item text-uppercase supported-text text-nowrap align-items-center text-dwe-yellow';

  const liContent = document.createElement('span');
  liContent.className = 'fs-4 fw-bold';
  liContent.textContent = 'Unterstützt von';

  const liPostfix = document.createElement('span');
  liPostfix.className = 'px-1';
  liPostfix.textContent = `///\/`;

  li.appendChild(liContent);
  li.appendChild(liPostfix);

  return li;
}

function addSupportedByLi() {
  const ul = document.getElementById('supporters-list');

  if (ul === null || ul.children.length < 15) {
    return;
  }

  const li = createSupportedByLi();

  ul.insertBefore(li, ul.children[0]);

  for (let i = 0; i <= ul.children.length; i++) {
    if ((i + 1) % 6 === 0) {
      const li = createSupportedByLi();

      ul.insertBefore(li, ul.children[i + 1]);
    }
  }
}

// Adjust this value to control the animation speed
const ANIMATION_SPEED = 0.75;
const IS_TOUCH_DEVICE = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

// Based on: https://codepen.io/tmhrtwg/pen/PvywxY
document.addEventListener('turbo:load', () => {
  const ul = document.getElementById('supporters-list');

  if (ul === null) {
    return;
  }

  shuffleSupportersList();
  addSupportedByLi();
  prepareSupportsList();

  let isPlayingAnimation = true;
  const containerElem = document.getElementById('supports-container');
  const leftSideOfContainer = containerElem.getBoundingClientRect().left;
  const listElem = document.getElementById('supporters-list');

  let currentLeftValue = 0;

  function animationLoop() {
    const firstListItem = listElem.querySelector('.supporters-list-item:first-child');
    let rightSideOfFirstItem = firstListItem.getBoundingClientRect().right;

    if (rightSideOfFirstItem < leftSideOfContainer) {
      currentLeftValue = -1;

      const childToMoveToEnd = firstListItem.cloneNode(true);

      listElem.appendChild(childToMoveToEnd);
      listElem.removeChild(firstListItem);
    }

    // Adjust the animation speed by multiplying `currentLeftValue` by `ANIMATION_SPEED`
    listElem.style.marginLeft = `${currentLeftValue * ANIMATION_SPEED}px`;

    currentLeftValue--;

    if (isPlayingAnimation) {
      requestAnimationFrame(animationLoop);
    }
  }

  if (!IS_TOUCH_DEVICE) {
    containerElem.onmouseenter = function () {
      isPlayingAnimation = false;
    };

    containerElem.onmouseleave = function () {
      isPlayingAnimation = true;

      requestAnimationFrame(animationLoop);
    };
  }

  requestAnimationFrame(animationLoop);
});
