import { highlightButton, unhighlightButton } from "./helpers";

document.addEventListener('turbo:load', () => {
  if (document.querySelector('#filter-tags')) {
    const filterButtons = document.querySelectorAll(".news-tag-filter-button");
    const filterResetButton = document.querySelector("#news-tag-filter-reset-button");
    const newsColumns = document.querySelectorAll(".news-column")

    const fadeOut = (newsColumn) => {
      newsColumn.style.transition = "opacity 500ms";
      newsColumn.style.opacity = "0.3";
      setTimeout(() => newsColumn.classList.add('d-none'), 500);
    }

    const fadeIn = (newsColumn) => {
      newsColumn.classList.remove('d-none')
      newsColumn.style.transition = "opacity 500ms";
      setTimeout(() => newsColumn.style.opacity = "1", 500);
    }

    const isInactive = (btn) => {
      return btn.classList.contains('inactive');
    }

    const showAllNewsColumns = () => {
      newsColumns.forEach((newsColumn) => fadeIn(newsColumn))
    }

    const filterNews = (filter_tag) => {
      newsColumns.forEach((newsColumn) => {
        const newsTags = newsColumn.dataset.newsTags.split(',')
        const hasTags = newsTags.includes(filter_tag);

        hasTags ? fadeIn(newsColumn) : fadeOut(newsColumn);
      })
    }

    const resetFiltering = () => {
        highlightButton(filterResetButton); // Active filter reset button
        filterButtons.forEach((btn) => unhighlightButton(btn)); // Deactivate all filter tag buttons
        showAllNewsColumns(); // Show entire news index
    }

    // RESET FILTERING BTN
    filterResetButton.addEventListener('click', () => {
      if (isInactive(filterResetButton)) {
        resetFiltering();
      }
    });

    // TAG FILTER BTNs
    filterButtons.forEach((filter_button) => {
      filter_button.addEventListener('click', () => {
        if (isInactive(filter_button)) {
          filterButtons.forEach((btn) => unhighlightButton(btn)); // Unhighlight all buttons
          highlightButton(filter_button); // Highlight selected button
          unhighlightButton(filterResetButton); //Unhighlight reset button

          filterNews(filter_button.dataset.newsTag) // Hide news not containing active filter tag
        } else {
          resetFiltering();
        }
      })
    })
  }
});