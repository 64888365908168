document.addEventListener('turbo:load', () => {
  const urlParams = new URLSearchParams(window.location.search);
  const missingLang = urlParams.get("missingLang");

  if (missingLang) {
    const messages = {
      de: "⚠ Diese Seite ist auf deutsch nicht verfügbar. <a href='/'>Startseite</a> ⚠",
      en: "⚠ This page is not available in english. <a href='/en'>Start page</a> ⚠",
      fr: "⚠ Cette page n'est pas disponible en français <a href='/fr'>Page d'accueil</a> ⚠",
      ar: "⚠ هذه الصفحة غير متوفرة باللغة الإنجليزية <a href='/ar'>الصفحة الرئيسية</a> ⚠",
    };

    const messageContainer = document.querySelector("#missing-lang");
    const translatedMessage = messages[missingLang];

    if (translatedMessage) {
      messageContainer.innerHTML = translatedMessage;
      messageContainer.classList.remove('hidden');
    }
  }
});
