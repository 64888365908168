console.log('Vite ⚡️ Ruby loaded :)))')

import '~/scss/application.scss'
import '~/js/application.js'

// import all from bootstrap. we could also load specific modules instead
import * as bootstrap from 'bootstrap'

import lazySizes from 'lazysizes'

document.addEventListener('lazybeforeunveil', function(e){
    const placeholder = '/assets/img/cms_down_placeholder.png';
    const img = e.target;

    img.onerror = function() {
        this.src = placeholder;
        this.srcset = placeholder;
        this.dataset.srcset = placeholder;
    }
});
