import { fadeOut } from "./helpers";

document.addEventListener('turbo:load', () => {
    if (document.querySelector('#newsletter-form')) {
        // CHANGE NEWSLETTER FORM PATH DEPENDING ON ACTION SWITCH
        const newsletterForm = document.querySelector('#newsletter-form');
        const alertSuccess = document.querySelector('.alert-success');

        const formSubmitted = () => {
            alertSuccess.innerHTML = `
            <i class="bi bi-balloon-heart-fill me-2"></i>
                ${newsletterForm.dataset.successmsg}
            <i class="bi bi-balloon-heart-fill mx-2"></i>
            `
            alertSuccess.classList.remove('d-none');
    
            document.querySelectorAll('.form-control-lg').forEach((field) => field.value = '')
            document.querySelector('#privacy-check').checked = false;

            setTimeout(() => fadeOut(alertSuccess), 5000);
        }

        document.querySelectorAll('.newsletter-lang-selector').forEach((checkBox) => {
            checkBox.addEventListener('change', () => {
                if (checkBox.checked) newsletterForm.action = checkBox.value;
            });
        });

        newsletterForm.addEventListener('submit', (event) => {
            event.preventDefault();

            // Create a URLSearchParams object and append the form field values
            const formData = new URLSearchParams();
            formData.append('VORNAME', document.querySelector('#VORNAME').value);
            formData.append('NACHNAME', document.querySelector('#NACHNAME').value);
            formData.append('EMAIL', document.querySelector('#EMAIL').value);

            // Send the form data to the server using fetch
            fetch('https://bdb94c27.sibforms.com/serve/MUIEAIPFYBtpFIW-rv8lpqgGaC_EL3Vw3fuB7zZ4Wt4D3Jbo137OMtDn2In_yC6ToL0JG5zohcj37k0__1JIvgPqNufU9QQ3Ay0ZTcuoNqjk8Vlugzv8fGVbRv1UD72bxltK7eRYEBInCSVDcxPqeIc2lr1xoG73qJFpx9XuoxGTW3gQq7RyPaT5fL3Kb3n3rsiQJdpGED8762I5', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData
            })
                .then(response => {
                    console.log(response);
                    formSubmitted();
                });
        })
    }
});