document.addEventListener('turbo:load', () => {
  const closeNavbars = () => {
    document.querySelectorAll(".mobilenav").forEach(navbar => {
      navbar.classList.add("hidden");
    })
  }

  document.querySelectorAll(".mobilenav-close-button").forEach(button => {
    button.addEventListener("click", closeNavbars);
  });

  const openMobilenavLanguage = () => {
    document.querySelector("#language-mobilenav").classList.remove("hidden");
  }

  const openMobilenavPages = () => {
    document.querySelector("#pages-mobilenav").classList.remove("hidden");
  }

  const toggleNavLanguage = () => {
    document.querySelector("#language-nav").classList.toggle("hidden");
    document.querySelector("#nav-current").classList.add("hidden");
    document.querySelector("#open-nav-current").classList.remove("active");
    document.querySelectorAll("#language-nav-open-button > img").forEach(button => {
      button.classList.toggle("hidden");
    });  }

  const langMobileNavOpenBtn = document.querySelector("#language-mobilenav-open-button");
  if (langMobileNavOpenBtn) {
    langMobileNavOpenBtn.addEventListener("click", openMobilenavLanguage);
    langMobileNavOpenBtn.addEventListener("keydown", openMobilenavLanguage);
  }

  const pagesMobileNavOpenBtn = document.querySelector("#pages-mobilenav-open-button");
  if (pagesMobileNavOpenBtn) {
    pagesMobileNavOpenBtn.addEventListener("click", openMobilenavPages);
    pagesMobileNavOpenBtn.addEventListener("keydown", openMobilenavPages);

  }

  const langNavOpenBtn = document.querySelector("#language-nav-open-button");
  if (langNavOpenBtn) {
    langNavOpenBtn.addEventListener("click", toggleNavLanguage);
    langNavOpenBtn.addEventListener("keydown", toggleNavLanguage);
  }

  const openNavCurrent = () => {
    document.querySelector("#nav-current").classList.remove("hidden");
    document.querySelector("#open-nav-current").classList.add("active");
    document.querySelector("#language-nav").classList.add("hidden");
  }

  const navCurrent = document.querySelector("#open-nav-current");
  if (navCurrent) {
    navCurrent.addEventListener("click", openNavCurrent);
    navCurrent.addEventListener("keydown", openNavCurrent);
  }
})
