export function fadeOut(el) {
    el.style.opacity = 1;

    (function fade() {
        if ((el.style.opacity -= 0.025) < 0) {
            el.classList.add('d-none');
            el.style.opacity = 1;
        } else {
            requestAnimationFrame(fade);
        }
    })();
}
export const highlightButton = (btn) => {
    btn.classList.add('btn-dwe-purple-yellow-font');
    btn.classList.remove('btn-dwe-yellow-purple-font');
    btn.classList.remove('inactive');
    btn.blur();
}

export const unhighlightButton = (btn) => {
    btn.classList.remove('btn-dwe-purple-yellow-font');
    btn.classList.add('btn-dwe-yellow-purple-font');
    btn.classList.add('inactive');
    btn.blur();
}
