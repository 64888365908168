import { highlightButton, unhighlightButton } from "./helpers";

document.addEventListener('turbo:load', () => {
  if (document.querySelector('#material-filters')) {

    const filterCatButtons = document.querySelectorAll(".material-category-filter-button");
    const resetFiltersButton = document.querySelector(".reset-filters-button");
    const materialEntries = document.querySelectorAll(".material-entry")

    resetFiltersButton.addEventListener("click", () => {
      highlightButton(resetFiltersButton);

      filterCatButtons.forEach((btn) => unhighlightButton(btn))

      materialEntries.forEach((materialEntry) => { materialEntry.classList.remove('d-none') })
    })

    filterCatButtons.forEach((filterCatButton) => {
      filterCatButton.addEventListener('click', () => {

        unhighlightButton(resetFiltersButton);
        filterCatButtons.forEach((btn) => unhighlightButton(btn))
        highlightButton(filterCatButton);

        let activeCat = filterCatButton.dataset.cat;

        materialEntries.forEach((materialEntry) => {
          if (materialEntry.dataset.cats.includes(activeCat)) {
            materialEntry.classList.remove('d-none');
          } else {
            materialEntry.classList.add('d-none');
          }
        })
      })
    })
  }
});
