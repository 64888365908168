import './helpers';
import './navbars';
import './material_filter'
import './news_tags_filter';
import './newsletter_form';
import './missing_translation_message';
import './wahltool';
import './supporters';
import '@hotwired/turbo'
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://3a88c2e5868f47e486b883a6aec35be0@o4504486003539968.ingest.sentry.io/4504486013566976",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

document.addEventListener('turbo:load', () => {
  // with turbo we do not have document.referrer, so save it in a window var.
  // It is used in 404.html
  window['referer'] = window.location.href;

  // make sure, that anchor-based scroll moves are animated,
  // so that users realize that page scrolled
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  })


  // make sure, that video is only downloaded, if visible
  // Otherwise, both files, mobile and desktop are downloaded

  // Select all the video elements on the page
  var videos = document.querySelectorAll("video");
  // Create an IntersectionObserver
  var observer = new IntersectionObserver(function (entries) {
    // Loop through each video
    for (var i = 0; i < entries.length; i++) {
      var entry = entries[i];
      // Check if the video element is in view
      if (entry.isIntersecting) {
        var video = entry.target;
        // Get the video source from the data-src attribute
        var src = video.getAttribute("data-src");
        // Set the video source
        video.src = src;
        // Stop observing the element
        observer.unobserve(video);
      }
    }
  });

  // Loop through the videos and observe each one
  for (var i = 0; i < videos.length; i++) {
    observer.observe(videos[i]);
  }
});
